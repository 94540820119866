import React, { useState, useEffect } from "react";

import Cookies from "js-cookie";

import NavigationBar from "../../components/NavBar.js";

export default function Settings() {
  const [settings, setSettings] = useState([]);

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const accessToken = Cookies.get("access_token");

    fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/settings`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Cookie: `admin2=${Cookies.get("admin2")}`,
        "X-Auth": `admin2=${Cookies.get("admin2")}`,
      },
    })
      .then((response) => {
        if (response.status === 403) {
          window.location.href = "/login";
        } else {
          return response.json(); // Get the user data
        }
      })
      .then((data) => {
        if (data) {
          setSettings(data); // Set user data to the state

          setIsChecked(data.waitlist_open);
        }
      })
      .catch((error) => console.error("Error fetching users:", error));
  }, []);

  function updateSettings() {
    fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/settings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("access_token")}`,
        Cookie: `admin2=${Cookies.get("admin2")}`,
        "X-Auth": `admin2=${Cookies.get("admin2")}`,
      },
      body: JSON.stringify({
        waitlist_open: !isChecked,
      }),
    })
      .then((data) => data.json()) // Parse the response data
      .then((data) => {
        if (data.status === 403) {
          window.location.href = "/login";
        } else if (data.status === 401) {
          window.location.href = "/admin/authenticate";
        } else if (data.status === 200) {
          // Find the user with the matching discord ID
          setIsChecked(!data.waitlist_open);
        }
      })
      .catch((error) => console.error(`Error banning:`, error));
  }

  return (
    <div>
      <NavigationBar />

      <div className="users">
        <h2> Settings </h2>

        <div style={{ width: 300, maxHeight: 40, float: "left" }}>
          <input
            type="checkbox"
            id="checkbox"
            checked={isChecked}
            onChange={updateSettings}
          />
          <label htmlFor="checkbox"> Waitlist Open </label>
        </div>
      </div>
    </div>
  );
}
