import React, { useState, useEffect } from "react";
import axios from "axios";
import queryString from "query-string";
import Cookies from "js-cookie";

import WaitlistSignup from "../components/WaitlistSignup";

import "../assets/stylesheets/dashboard.scss";

import CreditCardIcon from "../assets/images/creditcard.svg";
import EnvelopeIcon from "../assets/images/envelope.svg";
import PersonIcon from "../assets/images/person.svg";

import VisaLogo from "../assets/images/visa.png";
import MastercardLogo from "../assets/images/mastercard.png";
import AmexLogo from "../assets/images/amex.png";
import ShoePlexLogo from "../assets/images/logo.png";

export default function Dashboard({ userInfoResponse }) {
  const [userInfo, setUserInfo] = useState(userInfoResponse);
  const [joinedWaitlist, setJoinedWaitlist] = useState(false); // Add this state
  const [waitlistOpen, setWaitlistOpen] = useState(true); // Add this state

  function getCardIcon(cardType) {
    switch (cardType) {
      case "visa":
        return VisaLogo;
      case "mastercard":
        return MastercardLogo;
      case "amex":
        return AmexLogo;
      default:
        return CreditCardIcon;
    }
  }

  function formatUnixTimestamp(timestamp) {
    const currentDate = new Date();
    const targetDate = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds

    const daysDiff = Math.floor(
      (targetDate - currentDate) / (1000 * 60 * 60 * 24)
    );

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const daySuffix = (num) => {
      if (num >= 11 && num <= 13) {
        return "th";
      }
      switch (num % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const formattedDate = `${targetDate.getDate()}${daySuffix(
      targetDate.getDate()
    )} ${months[targetDate.getMonth()]}`;

    if (daysDiff === 0) {
      return `Today (${formattedDate})`;
    } else if (daysDiff === 1) {
      return `Tomorrow (${formattedDate})`;
    } else if (daysDiff > 0) {
      return `${formattedDate} (in ${daysDiff} days)`;
    } else {
      return `${formattedDate} (${Math.abs(daysDiff)} days ago)`;
    }
  }

  async function signOut() {
    Cookies.remove("access_token");
    Cookies.remove("invitation_token");

    window.location.href = "/login";
  }

  async function leaveWaitlist() {
    const accessToken = Cookies.get("access_token");

    const userInfoResponse = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/api/waitlist/remove`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    setUserInfo(userInfoResponse.data);
  }

  async function loadSettings() {
    const settingsResponse = await axios.get(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/api/settings`
    );

    setWaitlistOpen(settingsResponse.data.waitlist_open);
  }

  function getLoadingText() {
    let invitationCookie = Cookies.get("invitation_token");

    if (invitationCookie) {
      return "Your payment is being processed.";
    } else {
      return "Your waitlist entry is being processed.";
    }
  }

  useEffect(() => {
    // Function to fetch user info using the provided access token
    async function fetchUserInfoWithToken(accessToken) {
      try {
        const userInfoResponse = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/user-info`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        setUserInfo(userInfoResponse.data);
      } catch (error) {
        console.error("Error fetching user info:", error);
        window.location.href = "/login";
      }
    }

    // Check if invitation token is available in URL

    const params = queryString.parse(window.location.search);
    const invitationToken = params.invitation_token;

    if (invitationToken) {
      Cookies.set("invitation_token", invitationToken);
    }

    // Check if access token is available in cookies

    const accessToken = Cookies.get("access_token");

    if (!userInfo) {
      // Get authorization code from URL parameters
      const params = queryString.parse(window.location.search);
      const code = params.code;

      // Fetch user info when userInfo is not available
      const fetchUserInfo = async () => {
        try {
          // If access token is not available in cookies, request it using the authorization code
          if (accessToken === undefined) {
            const response = await axios.post(
              `${process.env.REACT_APP_BACKEND_DOMAIN}/api/discord/callback`,
              { code: code }
            );
            const newAccessToken = response.data.access_token;

            Cookies.set("access_token", newAccessToken);
            fetchUserInfoWithToken(newAccessToken);
          } else {
            fetchUserInfoWithToken(accessToken);
          }
        } catch (error) {
          console.error("Error fetching user info:", error);

          // Redirect to /login if the access token is invalid:
          window.location.href = "/login";
        }
      };

      fetchUserInfo();
    }

    loadSettings();

    if (joinedWaitlist) {
      const pollUserInfo = setInterval(() => {
        if (accessToken !== undefined) {
          fetchUserInfoWithToken(accessToken);
        }
      }, 1000); // Poll every 3 seconds

      return () => clearInterval(pollUserInfo); // Clean up interval on component unmount
    }
  }, [userInfo, joinedWaitlist]);

  if (!userInfo) {
    return <div></div>;
  }

  return (
    <div className="dashboard">
      <div className="loading" id="loading">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <p>{getLoadingText()}</p>
      </div>

      <div className="box" id="box">
        <div className="user">
          <img
            src={ShoePlexLogo}
            alt="ShoePlex logo"
            className="logo"
            draggable="false"
            style={{
              float: "left",
              marginLeft: "32",
              border: "0",
              height: 48,
              width: 48,
              margin: 8,
              paddingLeft: 2,
            }}
          />
          <img
            src={`https://cdn.discordapp.com/avatars/${userInfo.discord.id}/${userInfo.discord.avatar}.png`}
            alt="User avatar"
            draggable="false"
          />
          <p> {userInfo.discord.username} </p>
        </div>

        {userInfo.dashboard.userStatus === "active" ||
        userInfo.dashboard.userStatus === "cancelled" ? (
          <div>
            <div className="membership">
              <div className="subtitle">
                <img
                  src={PersonIcon}
                  alt="Credit card icon"
                  className="person"
                  draggable="false"
                />
                <p> Membership </p>
              </div>

              <div className="content" style={{ width: "520px" }}>
                <div className="renewal">
                  <p> Renewal </p>
                  <div className="renewal-box">
                    {userInfo.dashboard.userStatus === "cancelled" ? (
                      <p>Subscription Cancelled</p>
                    ) : (
                      <p
                        style={{
                          color: "black",
                          opacity: "1",
                          "font-weight": "400",
                        }}
                      >
                        {formatUnixTimestamp(
                          userInfo.dashboard.billing.next_payment
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <hr className="divider" />

            <div className="billing">
              <div className="subtitle">
                <img
                  src={CreditCardIcon}
                  alt="Credit card icon"
                  draggable="false"
                />
                <p> Billing </p>
              </div>

              <div className="payment-details">
                <img
                  src={getCardIcon(userInfo.dashboard.billing.card_brand)}
                  alt="Visa logo"
                  className="provider"
                  draggable="false"
                />

                <p style={{ textTransform: "capitalize" }}>
                  {" "}
                  {userInfo.dashboard.billing.card_brand} ending in{" "}
                  {userInfo.dashboard.billing.card_last4}{" "}
                </p>
              </div>
            </div>

            <hr className="divider" />

            <div className="support">
              <div className="subtitle">
                <img
                  src={EnvelopeIcon}
                  alt="Envelope icon"
                  className="envelope"
                  draggable="false"
                />
                <p> Support </p>
              </div>

              <div className="content">
                <div className="staffmember">
                  <p> Email </p>
                  <div className="renewal-box">support@shoeplex.io</div>
                </div>

                <div className="staffmember">
                  <p> Support Member #1 </p>
                  <div className="renewal-box">ShoePlex Owner [STAFF]</div>
                </div>

                <div className="staffmember">
                  <p> Support Member #2 </p>
                  <div className="renewal-box">Skull [STAFF]</div>
                </div>

                <div className="staffmember">
                  <p> Support Member #3 </p>
                  <div className="renewal-box">Victor [STAFF]</div>
                </div>
              </div>
            </div>

            <div className="sign-out" onClick={signOut}>
              <p> Sign Out </p>
            </div>

            <hr className="divider" style={{ opacity: "0" }} />
          </div>
        ) : userInfo.dashboard.userStatus === "new" ? (
          // Check if waitlist is current open (/api/settings)
          waitlistOpen ? (
            <WaitlistSignup setJoinedWaitlist={setJoinedWaitlist} />
          ) : (
            <p className="title" style={{ lineHeight: 8 }}>
              {" "}
              Waitlist is currently closed.{" "}
            </p>
          )
        ) : userInfo.dashboard.userStatus === "waitlisted" ? (
          <div className="waitlist-management">
            <p className="title"> You're on the waitlist! </p>
            <p className="subheader">
              {" "}
              To update your payment method, please remove your entry and join
              again.{" "}
            </p>

            <div className="payment-details" style={{ marginTop: 12 }}>
              <img
                src={getCardIcon(userInfo.dashboard.billing.card_brand)}
                alt="Visa logo"
                className="provider"
                draggable="false"
              />
              <p>
                {" "}
                {userInfo.dashboard.billing.card_brand} ending in{" "}
                {userInfo.dashboard.billing.card_last4}{" "}
              </p>
            </div>

            <div
              className="leave-waitlist"
              style={{ marginTop: 8 }}
              onClick={leaveWaitlist}
            >
              <p> Remove Waitlist Entry </p>
            </div>

            <hr className="divider" style={{ opacity: "0" }} />
          </div>
        ) : userInfo.dashboard.userStatus === "banned" ? (
          <p style={{ padding: 24 }}>
            {" "}
            <br /> <br /> <br /> You have been banned from ShoePlex. For
            information regarding your ban, please email support@shoeplex.io.{" "}
          </p>
        ) : userInfo.dashboard.userStatus === "expired" ? (
          <p> expired </p>
        ) : (
          <p> error </p>
        )}
      </div>
    </div>
  );
}
