import React, { useEffect } from "react";
import axios from "axios";
import queryString from "query-string";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom

import "../assets/stylesheets/login.scss";

function Login({ setUserInfoResponse }) {
  console.log("[PAGE] Login");

  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = () => {
    const queryParams = queryString.stringify({
      response_type: "code",
      client_id: process.env.REACT_APP_DISCORD_APPLICATION_CLIENT_ID,
      redirect_uri:
        process.env.REACT_APP_FRONTEND_DOMAIN +
        process.env.REACT_APP_DISCORD_OAUTH2_REDIRECT_PATH,
      scope: "guilds.join identify",
    });

    window.location.href = `https://discord.com/api/oauth2/authorize?${queryParams}`;
  };

  const handleCallback = async () => {
    const params = queryString.parse(window.location.search);
    if (params.code) {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/discord/callback`,
        { code: params.code }
      );
      const accessToken = response.data.access_token;

      console.log("Access Token: ", response.data);

      // Fetch user info after successful authentication
      const userInfoResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/user-info`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      // Set user info response in App component state
      console.log("User Info: ", userInfoResponse.data);
      setUserInfoResponse(userInfoResponse.data);

      // Redirect to /dashboard after successful login
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    handleCallback(); // Call the handleCallback function when the component mounts
  }, [handleCallback]);

  return (
    <div className="container">
      <button className="loginButton" onClick={handleLogin}>
        Login with Discord
      </button>
    </div>
  );
}

export default Login;
