import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";

import Home from "./pages/admin/Home";
import Users from "./pages/admin/Users";
import BannedUsers from "./pages/admin/BannedUsers";
import CancelledUsers from "./pages/admin/CancelledUsers";
import Waitlist from "./pages/admin/Waitlist";
import Settings from "./pages/admin/Settings";
import User from "./pages/admin/User";
import Authenticate from "./pages/admin/Authenticate";

function App() {
  // State to hold user info response
  const [userInfoResponse, setUserInfoResponse] = useState(null);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Login setUserInfoResponse={setUserInfoResponse} />}
        />

        <Route
          path="login"
          element={<Login setUserInfoResponse={setUserInfoResponse} />}
        />
        <Route
          path="dashboard"
          element={<Dashboard userInfoResponse={userInfoResponse} />}
        />

        <Route
          path="admin"
          element={<Home userInfoResponse={userInfoResponse} />}
        />
        <Route
          path="admin/authenticate"
          element={<Authenticate userInfoResponse={userInfoResponse} />}
        />
        <Route
          path="admin/users"
          element={<Users userInfoResponse={userInfoResponse} />}
        />
        <Route
          path="admin/banned-users"
          element={<BannedUsers userInfoResponse={userInfoResponse} />}
        />
        <Route
          path="admin/cancelled-users"
          element={<CancelledUsers userInfoResponse={userInfoResponse} />}
        />
        <Route
          path="admin/waitlist"
          element={<Waitlist userInfoResponse={userInfoResponse} />}
        />
        <Route
          path="admin/settings"
          element={<Settings userInfoResponse={userInfoResponse} />}
        />
        <Route
          path="admin/user-details"
          element={<User userInfoResponse={userInfoResponse} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
