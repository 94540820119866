import React, { useState, useEffect } from "react";
import NavigationBar from "../../components/NavBar.js";
import Cookies from "js-cookie";

import "../../assets/stylesheets/users.scss";

export default function Home() {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteStatus, setInviteStatus] = useState(""); // 'sending', 'success', 'error'

  useEffect(() => {
    const accessToken = Cookies.get("access_token");
    const admin2FA = Cookies.get("admin2");

    fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/users?type=overdue`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Cookie: `admin2=${admin2FA}`,
          "X-Auth": `admin2=${Cookies.get("admin2")}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 403) {
          window.location.href = "/login";
        } else if (response.status === 401) {
          window.location.href = "/admin/authenticate";
        } else {
          return response.json(); // Get the user data
        }
      })
      .then((data) => {
        if (data) {
          setUsers(data); // Set user data to the state
        }
      })
      .catch((error) => console.error("Error fetching users:", error));
  }, []);

  const handleInviteClick = async () => {
    setInviteStatus("sending");

    try {
      const accessToken = Cookies.get("access_token");

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/users/invite?email=${inviteEmail}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Cookie: `admin2=${Cookies.get("admin2")}`,
            "X-Auth": `admin2=${Cookies.get("admin2")}`,
          },
        }
      );

      if (response.status === 200) {
        setInviteStatus("success");
        setInviteEmail("");
      } else if (response.status == 401) {
        window.location.href = "/admin/authenticate";
      } else if (response.status == 403) {
        window.location.href = "/login";
      } else {
        setInviteStatus("error");
      }
    } catch (error) {
      setInviteStatus("error");
    }
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div>
      <NavigationBar />

      <div className="users">
        <h2> Home </h2>

        <div className="invite-section">
          <h3> Invite New Member </h3>

          <input
            type="email"
            placeholder="Enter email"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
          />
          <button
            style={{ width: 150 }}
            onClick={handleInviteClick}
            disabled={inviteStatus === "sending"}
          >
            {inviteStatus === "sending" ? (
              <span className="spinner"></span>
            ) : (
              "Send Invitation"
            )}
          </button>
          {inviteStatus === "success" && (
            <p className="success-text">User Invitation Sent</p>
          )}
          {inviteStatus === "error" && (
            <p className="error-text">Failed to invite user</p>
          )}
        </div>

        <div className="divider" />

        <div className="overdue-section">
          <h3> Overdue Users </h3>

          {users.length === 0 ? (
            <p style={{ float: "left" }}> No overdue users! </p>
          ) : (
            <table className="user-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Discord ID</th>
                  <th>Stripe ID</th>
                  <th>Renewal Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td onClick={() => handleCopyToClipboard(user.name)}>
                      {user.name}
                    </td>
                    <td onClick={() => handleCopyToClipboard(user.email)}>
                      {user.email}
                    </td>
                    <td onClick={() => handleCopyToClipboard(user.phone)}>
                      {user.phone}
                    </td>
                    <td onClick={() => handleCopyToClipboard(user.userID)}>
                      {user.userID}
                    </td>
                    <td
                      onClick={() =>
                        handleCopyToClipboard(user.billing.customer_id)
                      }
                    >
                      {user.billing.customer_id}
                    </td>
                    <td
                      onClick={() =>
                        handleCopyToClipboard(user.billing.customer_id)
                      }
                    >
                      {user.billing.next_renewal}
                    </td>
                    <td onClick={() => handleCopyToClipboard(user.userStatus)}>
                      {user.userStatus}
                    </td>
                    <td>
                      <a
                        href={`https://dashboard.stripe.com/customers/${user.billing.customer_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Stripe
                      </a>
                      <a
                        href={`/admin/user-details/?id=${user.userID}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Details
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
}
