import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
import "../assets/stylesheets/navbar.scss"; // Import your CSS for styling

export default function NavigationBar() {
  const location = useLocation();

  return (
    <div className="navigation-bar">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="links">
        <NavLinkWithBackground
          to="/admin"
          active={location.pathname === "/admin"}
        >
          Home
        </NavLinkWithBackground>
        <NavLinkWithBackground
          to="/admin/users"
          active={location.pathname === "/admin/users"}
        >
          Users
        </NavLinkWithBackground>
        <NavLinkWithBackground
          to="/admin/waitlist"
          active={location.pathname === "/admin/waitlist"}
        >
          Waitlist
        </NavLinkWithBackground>
        <NavLinkWithBackground
          to="/admin/banned-users"
          active={location.pathname === "/admin/banned-users"}
        >
          Banned
        </NavLinkWithBackground>

        <NavLinkWithBackground
          to="/admin/cancelled-users"
          active={location.pathname === "/admin/cancelled-users"}
        >
          Cancelled
        </NavLinkWithBackground>

        <NavLinkWithBackground
          to="/admin/settings"
          active={location.pathname === "/admin/settings"}
        >
          Settings
        </NavLinkWithBackground>
      </div>
    </div>
  );
}

function NavLinkWithBackground({ to, active, children }) {
  return (
    <Link to={to} className={`nav-link ${active ? "active" : ""}`}>
      {children}
    </Link>
  );
}
