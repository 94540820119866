import React, { useState, useEffect } from "react";
import NavigationBar from "../../components/NavBar.js";
import Cookies from "js-cookie";

import "../../assets/stylesheets/users.scss";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const accessToken = Cookies.get("access_token");

    fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/users?type=active`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("access_token")}`,
          Cookie: `admin2=${Cookies.get("admin2")}`,
          "X-Auth": `admin2=${Cookies.get("admin2")}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 403) {
          window.location.href = "/login";
        } else if (response.status === 401) {
          window.location.href = "/admin/authenticate";
        } else {
          return response.json(); // Get the user data
        }
      })
      .then((data) => {
        if (data) {
          setUsers(data); // Set user data to the state
        }
      })
      .catch((error) => console.error("Error fetching users:", error));
  }, []);

  function formatUnixTimestamp(timestamp) {
    if (!timestamp) {
      return "N/A";
    }
    const currentDate = new Date();
    const targetDate = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds

    const daysDiff = Math.floor(
      (targetDate - currentDate) / (1000 * 60 * 60 * 24)
    );

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const daySuffix = (num) => {
      if (num >= 11 && num <= 13) {
        return "th";
      }
      switch (num % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const formattedDate = `${targetDate.getDate()}${daySuffix(
      targetDate.getDate()
    )} ${months[targetDate.getMonth()]}`;

    if (daysDiff === 0) {
      return `Today (${formattedDate})`;
    } else if (daysDiff === 1) {
      return `Tomorrow (${formattedDate})`;
    } else if (daysDiff > 0) {
      return `${formattedDate} (in ${daysDiff} days)`;
    } else {
      return `${formattedDate} (${Math.abs(daysDiff)} days ago)`;
    }
  }

  function unbanUser(discordID) {
    fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/user/unban`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("access_token")}`,
        Cookie: `admin2=${Cookies.get("admin2")}`,
        "X-Auth": `admin2=${Cookies.get("admin2")}`,
      },
      body: JSON.stringify({ discordID }),
    })
      .then((data) => data.json()) // Parse the response data
      .then((data) => {
        if (data.status === 403) {
          window.location.href = "/login";
        } else if (data.status === 401) {
          window.location.href = "/admin/authenticate";
        } else if (data.status === 200) {
          // Find the user with the matching discord ID
          const updatedUsers = users.map((user) => {
            if (user.userID === discordID) {
              // Update the user's status with the one from the response
              return {
                ...user,
                userStatus: data.newStatus, // Assuming the response contains the new status
              };
            }
            return user;
          });

          setUsers(updatedUsers); // Update the state with the updated user data
        } else {
          // Handle error cases
          console.error("Error updating user:", data.error);
        }
      })
      .catch((error) => console.error(`Error banning:`, error));
  }

  function cancelUser(discordID) {
    fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/user/cancel`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("access_token")}`,
        Cookie: `admin2=${Cookies.get("admin2")}`,
        "X-Auth": `admin2=${Cookies.get("admin2")}`,
      },
      body: JSON.stringify({ discordID }),
    })
      .then((data) => data.json()) // Parse the response data
      .then((data) => {
        if (data.status === 403) {
          window.location.href = "/login";
        } else if (data.status === 401) {
          window.location.href = "/admin/authenticate";
        } else if (data.status === 200) {
          // Find the user with the matching discord ID
          const updatedUsers = users.map((user) => {
            if (user.userID === discordID) {
              // Update the user's status with the one from the response
              return {
                ...user,
                userStatus: data.newStatus, // Assuming the response contains the new status
              };
            }
            return user;
          });

          setUsers(updatedUsers); // Update the state with the updated user data
        } else {
          // Handle error cases
          console.error("Error updating user:", data.error);
        }
      })
      .catch((error) => console.error(`Error banning:`, error));
  }

  function banUser(discordID) {
    fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/user/ban`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("access_token")}`,
        Cookie: `admin2=${Cookies.get("admin2")}`,
        "X-Auth": `admin2=${Cookies.get("admin2")}`,
      },
      body: JSON.stringify({ discordID }),
    })
      .then((data) => data.json()) // Parse the response data
      .then((data) => {
        if (data.status === 403) {
          window.location.href = "/login";
        } else if (data.status === 401) {
          window.location.href = "/admin/authenticate";
        } else if (data.status === 200) {
          // Find the user with the matching discord ID
          const updatedUsers = users.map((user) => {
            if (user.userID === discordID) {
              // Update the user's status with the one from the response
              return {
                ...user,
                userStatus: data.newStatus, // Assuming the response contains the new status
              };
            }
            return user;
          });

          setUsers(updatedUsers); // Update the state with the updated user data
        } else {
          // Handle error cases
          console.error("Error updating user:", data.error);
        }
      })
      .catch((error) => console.error(`Error banning:`, error));
  }

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  const filteredUsers = Array.isArray(users)
    ? users
        .filter(
          (user) =>
            user.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.userID?.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .sort((a, b) => {
          const nextRenewalA = a.billing?.next_renewal || 0;
          const nextRenewalB = b.billing?.next_renewal || 0;
          return nextRenewalA - nextRenewalB;
        })
    : [];

  return (
    <div>
      <NavigationBar />

      <div className="users">
        <div className="page-header">
          <h2> Users </h2>
          <input
            type="text"
            className="search-bar"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <table className="user-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Discord ID</th>
              <th>Stripe ID</th>
              <th>Status</th>
              <th>Renewal</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td onClick={() => handleCopyToClipboard(user.name)}>
                  {user.name}
                </td>
                <td onClick={() => handleCopyToClipboard(user.email)}>
                  {user.email}
                </td>
                <td onClick={() => handleCopyToClipboard(user.userID)}>
                  {user.userID}
                </td>
                <td
                  onClick={() =>
                    handleCopyToClipboard(user.billing.customer_id)
                  }
                >
                  {user.billing.customer_id}
                </td>
                <td onClick={() => handleCopyToClipboard(user.userStatus)}>
                  {formatUnixTimestamp(user.billing.next_payment)}
                </td>
                <td onClick={() => handleCopyToClipboard(user.userStatus)}>
                  {user.userStatus}
                </td>
                <td>
                  <a
                    href={`https://dashboard.stripe.com/customers/${user.billing.customer_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Stripe
                  </a>
                  <a
                    href={`/admin/user-details/?id=${user.userID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Details
                  </a>
                  {user.userStatus !== "banned" ? (
                    <div style={{ float: "left" }}>
                      <button
                        className="red"
                        onClick={() => banUser(user.userID)}
                      >
                        Ban User
                      </button>

                      <button
                        className="red"
                        onClick={() => cancelUser(user.userID)}
                      >
                        Cancel Subscription
                      </button>
                    </div>
                  ) : (
                    <button
                      className="red"
                      onClick={() => unbanUser(user.userID)}
                    >
                      Unban User
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
