import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

import NavigationBar from "../../components/NavBar.js";

export default function User() {
  const [user, setUser] = useState([]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/user/${window.location.search}`,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("access_token")}`,
          Cookie: `admin2=${Cookies.get("admin2")}`,
          "X-Auth": `admin2=${Cookies.get("admin2")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => setUser(data))
      .catch((error) => console.error("Error fetching users:", error));
  }, []);

  return (
    <div>
      <NavigationBar />

      <h2> User </h2>

      {user.toString()}
    </div>
  );
}
