import React from "react";

import Cookies from "js-cookie";

import "../../assets/stylesheets/authenticate.scss";

export default function Authenticate() {
  function authenticate() {
    fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/api/admin/authenticate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        code: document.getElementById("code").value,
      }),
    })
      .then((data) => data.json()) // Parse the response data
      .then((data) => {
        if (data.status === "success") {
          Cookies.set("admin2", data.cookie);
          window.location.href = "/admin";
        } else {
          alert("Invalid code: " + data.status + " " + data.message);
        }
      })
      .catch((error) => console.error(`Error banning:`, error));
  }

  return (
    <div className="authentication-container">
      <div className="rounded-rectangle">
        <h3>2FA Verification</h3>
        <input id="code" type="text" placeholder="Enter verification code" />
        <button onClick={authenticate}>Verify</button>
      </div>
    </div>
  );
}
